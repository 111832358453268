<div class="parent" [style.width.px]="imgWidth" [style.height.px]="imgHeight">
  <div class="image" [style.background-image]="'url(' + getImgUrl() + ')'"></div>
  <div class="bounds">
    <ng-container *ngIf="isWordBounding">
      <div class="word {{selectedWord?.Id == item.Id ? 'selected': ''}}" *ngFor="let item of listCoordinates"
        [style.top.px]="getTopStyle(item)" [style.left.px]="getLeftStyle(item)" [style.width.px]="getWidthStyle(item)"
        [style.height.px]="getHeightStyle(item)" (click)="onWordBlockClick(item)" nz-tooltip [nzTooltipTitle]="item.Text">
      </div>
    </ng-container>
    <ng-container *ngIf="isBlockBouding">
      <div class="block" *ngFor="let item of listBlockCoordinates"
        [style.top.px]="getTopStyle(item)" [style.left.px]="getLeftStyle(item)" [style.width.px]="getWidthStyle(item)"
        [style.height.px]="getHeightStyle(item)" (click)="onWordBlockClick(item)" nz-tooltip [nzTooltipTitle]="item.Text">
      </div>
    </ng-container>
  </div>
</div>